<template>
    <div>
        <Row class="p-b-10">
            <i-col span="6">合同名称：</i-col>
            <i-col span="18">
                <Input v-model="model.name" placeholder="请填写合同名称" />
            </i-col>
        </Row>
        <Row class="p-b-5">
            <i-col span="6">设置合同客户：</i-col>
            <i-col span="18">
                (<span class="text-orange">PS：创建合同之后，客户将无法更改</span>)
                <i-select v-model="model.advertiserId" >
                    <i-option v-for="advertiser in advertisers" :key="advertiser.advertiserId" :value="advertiser.advertiserId">{{advertiser.advertiserName}}</i-option>
                </i-select>
            </i-col>
        </Row>
        <Row v-if="!isSubmit">
            <i-col span="6">报备品牌：</i-col>
            <i-col span="18">
                <tag v-for="brand in brandList" :key="brand.id" color="blue">
                    {{brand.name}} ({{brand.industryName}})
                </tag>
            </i-col>
        </Row>
        <Row class="text-right">
          <Button type="success" v-show="!isSubmit" @click="handleCreateCraft">创建合同</Button>
        </Row>
    </div>
</template>

<script>
import { getBrandList } from '@/api/crm/brand'
import { createDraft } from '@/api/scp/contract'
import { getOrderAdvertiser } from '@/api/order/order'

export default {
  data () {
    return {
      model: { name: '', advertiserId: 0 },
      advertisers: [],
      brandList: [],
      contractType: this.$store.getters.token.publisherSetting.contractType // 创建合同类型
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      getOrderAdvertiser({ userId: this.$store.getters.token.userInfo.userId, status: 1 }).then(res => {
        this.advertisers = res
      })
    },
    handleCreateCraft () {
      const craft = {
        createdByAdmin: false,
        name: this.model.name,
        advertiserId: this.model.advertiserId,
        subjectType: this.contractType === 1 ? 1 : 3 // 1广告主<->媒介主合同 3广告主<->代理商合同
      }
      createDraft(craft).then(res => {
        if (res && !res.errcode) {
          this.model.advertiserId = 0
          this.model.name = ''
          this.$Notice.success({ desc: '合同创建成功！' })

          this.$emit('on-create', res)
        }
      })
    }
  },
  computed: {
    isSubmit () {
      return this.model.advertiserId === undefined || this.model.advertiserId === 0
    }
  },
  watch: {
    'model.advertiserId' (val) {
      if (this.model.advertiserId && this.model.advertiserId !== 0) {
        getBrandList({ advertiserId: val }).then(res => {
          this.brandList = res
        })
      } else {
        this.brandList = []
      }
    }
  }
}
</script>
