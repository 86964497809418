import request from '@/utils/requestV2'
const qs = require('qs')
// 新增品牌 并主动报审 【已测】
export function addBrand (data) {
  return request({
    url: '/ooh-crm/v1/brand/addbrand',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 删除品牌 【已测】
export function deleteBrand (data) {
  return request({
    url: '/ooh-crm/v1/brand/deletebrand',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 根据brandId获取品牌 【已测】
export function getBrandDetail (data) {
  return request({
    url: '/ooh-crm/v1/brand/getbrand',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 根据companybrandid获取品牌 【已测】
export function getbycompanybrandid (data) {
  return request({
    url: '/ooh-crm/v1/brand/getbycompanybrandid',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取客户下的品牌列表
export function getBrandList (data) {
  return request({
    url: '/ooh-crm/v1/brand/getbrandlist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取品牌关键字
export function getKeywords (data) {
  return request({
    url: '/ooh-crm/v1/brand/getkeywords',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取媒介主下的品牌
export function getPublisherBrandList (data) {
  return request({
    url: '/ooh-crm/v1/brand/getpublisherbrandlist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 媒介主下的关键字是否有保护的品牌
export function hasProtectBrand (data) {
  return request({
    url: '/ooh-crm/v1/brand/hasprotectbrand',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 编辑品牌关键字
export function updateBrandKeyword (data) {
  return request({
    url: '/ooh-crm/v1/brand/updatebrandkeyword',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 编辑品牌行业
export function updateIndustry (data) {
  return request({
    url: '/ooh-crm/v1/brand/updateindustry',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取销售跨运营商报备的品牌
export function getreportbrandbyseller (data) {
  return request({
    url: '/ooh-crm/v1/brand/getreportbrandbyseller',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取销售跨运营商报备的品牌
export function getreportbrandbyoperation (data) {
  return request({
    url: '/ooh-crm/v1/brand/getreportbrandbyoperation',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取品牌列表
export function getAllBrands (data) {
  return request({
    url: '/ooh-crm/v1/brand/getAllBrands',
    method: 'post',
    data: qs.stringify(data)
  })
}
